import {
  AFFIRM_CDN_HOST,
  AFFIRM_MINIMUM_PAYMENT,
  AFFIRM_PUBLIC_API_KEY_BLUE,
} from '~/config'
import { isAffirmEnabled } from '~/utils/affirm'
import getFeatureFlags from '~/utils/get-feature-flags'

import { AffirmConfigStrategySelectorArguments } from './affirm-config.types'
import { isAddressInCanada } from './affirm-config.utils'

export const globalStrategyBlue = {
  matches: ({
    billingAddress,
    cart,
  }: AffirmConfigStrategySelectorArguments) => {
    const { affirmBlueEnabled } = getFeatureFlags()

    if (!affirmBlueEnabled) return false
    if (!isAffirmEnabled()) return false
    if (!AFFIRM_CDN_HOST.includes('global')) return false
    if (billingAddress && !isAddressInCanada(billingAddress)) return false
    if (cart.total < AFFIRM_MINIMUM_PAYMENT) return false
    if (cart.payloadCurrency !== 'CAD') return false
    if (cart.purchaseGroup !== 'blue') return false

    return true
  },
  config: ({ locale }: AffirmConfigStrategySelectorArguments) => ({
    public_api_key: AFFIRM_PUBLIC_API_KEY_BLUE,
    script: `${AFFIRM_CDN_HOST}/js/v2/affirm.js`,
    country_code: 'CAN',
    locale: `${locale}_CA`,
  }),
}
