import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'

import { globalStrategyBlue } from './global-strategy-blue'
import { globalStrategyDefault } from './global-strategy-default'
import { nonGlobalStrategy } from './non-global-strategy'

import type { AppState } from '~/reducers'

const STRATEGIES = [
  nonGlobalStrategy,
  globalStrategyDefault,
  globalStrategyBlue,
]

const useAffirmConfig = () => {
  const billingAddress = useSelector((state: AppState) =>
    (state.profile?.addresses || []).find(
      (address) => address.type === 'billing',
    ),
  )
  const locale = Cookies.get('locale')
  const cart = useSelector((state: AppState) => state.cart)
  const strategyArguments = { billingAddress, locale, cart }
  const matchingStrategy = STRATEGIES.find((strategy) =>
    strategy.matches(strategyArguments),
  )

  return {
    validForAffirm: !!matchingStrategy,
    config: matchingStrategy?.config(strategyArguments) || {},
  }
}

export default useAffirmConfig
